import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import cookiemonster from '../images/cookiemonster.jpg';

const MissionModal = (props) => {
  const {showMissionModal, toggleMission} = props;

  return (
      <Modal isOpen={showMissionModal} toggle={toggleMission} size="lg">
        <ModalHeader toggle={toggleMission}></ModalHeader>
        <ModalBody style={{ padding: '16px', fontSize: '14px' }}>
          <div className="about_img"><img src={cookiemonster} alt="Linzey & Luna" /></div>
          <p>Hi! I am Linzey.</p>
          <p>I currently reside in the Silverlake neighborhood of Los Angeles with my husband and two children. With over 15 years of experience in fashion, I am looking to partner with a brand that I can nurture & grow. Through longstanding relationships, a sharp understanding of the market and authentic passion for what I do, I am excited to discuss what I can bring to the table with you.</p>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn"
            type="button"
            onClick={toggleMission}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
      
  );
};

export default MissionModal;
