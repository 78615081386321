import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import resume from '../images/LINZEYSTROMMEN.png';

const ResumeModal = (props) => {
  const {showResumeModal, toggleResume} = props;

  const downloadFile = async () => {
    try {
        const url = 'https://www.linzeystrommen.com/downloads/LINZEYSTROMMEN.pdf';

        const resp = await fetch(url);
        const blob = await resp.blob();

        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.setAttribute('download', "LINZEY STROMMEN");
        const rootEl = document.getElementById('root');

      if (rootEl) {
        rootEl.appendChild(a);

        a.click();

        rootEl.removeChild(a);
      }
      } catch {
        console.log('error')
      }
    }

  return (
      <Modal isOpen={showResumeModal} toggle={toggleResume} size="lg">
        <ModalHeader toggle={toggleResume}>
          <button
            className="btn"
            type="button"
            onClick={downloadFile}
          >
            Download PDF
          </button>
        </ModalHeader>
        <ModalBody style={{ textAlign: 'center' }}>
          <div className='resume'>
            <img className="resume_img" src={resume} alt="Resume" />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn"
            type="button"
            onClick={toggleResume}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
      
  );
};

export default ResumeModal;
