import React, { useEffect, useState } from 'react';
import headshot from '../images/headshot.jpg';
import ribbon from '../images/ribbon.png';
import pearl from '../images/pearl.png';
import evileye from '../images/evileye.png';
import MissionModal from './MissionModal';
import ResumeModal from './ResumeModal';
import ReferencesModal from './ReferencesModal';

const Main = () => {
  const [showMissionModal, setMissionModal] = useState(false);
  const toggleMission = () => setMissionModal(!showMissionModal);

  const [showResumeModal, setResumeModal] = useState(false);
  const toggleResume = () => setResumeModal(!showResumeModal);

  const [showRefsModal, setRefsModal] = useState(false);
  const toggleRefs = () => setRefsModal(!showRefsModal);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main">
      <MissionModal showMissionModal={showMissionModal} toggleMission={toggleMission} />
      <ResumeModal showResumeModal={showResumeModal} toggleResume={toggleResume} />
      <ReferencesModal showRefsModal={showRefsModal} toggleRefs={toggleRefs} />
      <div className="nav">
        <div className="nav-item" onClick={toggleMission}><img src={ribbon} alt="Mission" />Mission</div>
        <div className="nav-item" onClick={toggleResume}><img src={evileye} alt="Resume" />Resume</div>
        <div className="nav-item" onClick={toggleRefs}><img src={pearl} alt="References" />References</div>
      </div>
      <div className="headshot">
        <img className="headshot-img" src={headshot} alt="Linzey Strommen" />
      </div>
    </div>
  );
};

export default Main;
