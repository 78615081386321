import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'


const ReferencesModal = (props) => {
  const {showRefsModal, toggleRefs} = props;

  return (
      <Modal isOpen={showRefsModal} toggle={toggleRefs} size="lg">
        <ModalHeader toggle={toggleRefs}></ModalHeader>
        <ModalBody>
          <div className="ref-table">
            <div>
              <div className="ref-row">
                <div className="ref-name">ELLEN KINNEY<div className="ref-title">PRESIDENT, ALC LTD.</div></div>
                <div className="ref-email"><a href="mailto:ellen@alcltd.com">ellen@alcltd.com</a></div>
                <div className="ref-number">646-228-3609</div>
              </div>
              <div className="ref-row">
                <div className="ref-name">LEAH LITTLE HALE<div className="ref-title">SENIOR BUYER, TOOTSIES</div></div>
                <div className="ref-email"><a href="mailto:leah@tootsies.com">leah@tootsies.com</a></div>
                <div className="ref-number">713-818-0396</div>
              </div>
              <div className="ref-row">
                <div className="ref-name">COURTNEY GRANT<div className="ref-title">VICE PRESIDENT OF BUYING, ELYSE WALKER</div></div>
                <div className="ref-email"><a href="mailto:courtney@elysewalker.com">courtney@elysewalker.com</a></div>
                <div className="ref-number">215-873-1740</div>
              </div>
            </div>
            <div>
              <div className="ref-row">
                <div className="ref-name">STEPHANIE BROWNE<div className="ref-title">SENIOR BUYER, REVOLVE</div></div>
                <div className="ref-email"><a href="mailto:stephanie.browne@revolve.com">stephanie.browne@revolve.com</a></div>
                <div className="ref-number"></div>
              </div>
              <div className="ref-row">
                <div className="ref-name">AMI LASSER<div className="ref-title">HEAD BUYER, RON HERMAN</div></div>
                <div className="ref-email"><a href="mailto:amilasser@icloud.com">amilasser@icloud.com</a></div>
                <div className="ref-number">310-801-0246</div>
              </div>
              <div className="ref-row">
                <div className="ref-name">KEENAN WALKER<div className="ref-title">CCO, MARKET HIGHLAND PARK</div></div>
                <div className="ref-email"><a href="mailto:keenanwalker@markethighlandpark.com">keenanwalker@markethighlandpark.com</a></div>
                <div className="ref-number">213-656-1390</div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn"
            type="button"
            onClick={toggleRefs}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
      
  );
};

export default ReferencesModal;





        