import React, { useEffect } from 'react';
import { Input } from 'reactstrap'

const Password = (props) => {
  const { setPassword } = props;
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="password">
      Please enter password <br />
      <Input name="password" type="password" onChange={(e) => setPassword(`${e.target.value}`)}/>
    </div>
  );
};

export default Password;
