import React, { useState } from 'react';
import header from './images/header.png';
import './styles/styles.css';
import { login } from './constants/constants.js';
import Main from './components/Main.jsx';
import Password from './components/Password.jsx';

function App() {
  // eslint-disable-next-line no-undef
  const [password, setPassword] = useState('');


  return (
    <div className="app">
      <header className="app-header">
        <div className="header">
          <img className="header-img" src={header} alt="Linzey Strommen" />
          {(password === login) && <div className="contact">
            323-770-6977
            <a href="mailto:linzey@linzeystrommen.com">linzey@linzeystrommen.com</a>
          </div>}
        </div>
      </header>
      {(password === login) ? (<Main />) : (<Password setPassword={(password) => setPassword(password)} />)}
    </div>
  );
}

export default App;
